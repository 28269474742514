import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Settings.css';

const API_URL = 'https://server.readingfun.net';

const Settings = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('3');
    const navigate = useNavigate();

    // Fetch user data on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_URL}/settings/user`, { withCredentials: true });
                const userData = response.data;

                // Populate the form fields with user data
                setUserName(userData.UserName || '');
                setPassword(''); // Don't populate password for security
                setFirstName(userData.FirstName || '');
                setLastName(userData.LastName || '');
                setEmail(userData.Email || '');
                setAge(userData.Age ? String(userData.Age) : '3'); // Convert age to string for select
            } catch (error) {
                console.error('Error fetching user data:', error);
                alert('Failed to load user data.');
            }
        };

        fetchUserData();
    }, []);

    const handleSettingsUpdate = async (e) => {
        e.preventDefault();
        try {
            const updatedUser = {
                username: userName,
                password: password,
                first_name: firstName,
                last_name: lastName,
                email: email,
                age: parseInt(age, 10) // Convert age back to integer
            };

            const response = await axios.put(`${API_URL}/settings/update`, updatedUser, { withCredentials: true });

            if (response.status === 200) {
                alert('Settings updated successfully!');
                navigate('/main');
            }
        } catch (error) {
            console.error(error);
            alert('Failed to update settings. Please try again.');
        }
    };

    return (
        <div className="settings-container">
            <img src="/images/ReadingFun-icon.png" alt="Reading Logo" className="reading-logo" />

            <form onSubmit={handleSettingsUpdate} className="settings-form">
                <div className="settings-form-row">
                    <div className="settings-form-column">
                        <label>Username</label>
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="settings-form-column">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Leave blank to keep current password"
                        />
                    </div>
                </div>
                <div className="settings-form-row">
                    <div className="settings-form-column">
                        <label>First Name</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="settings-form-column">
                        <label>Last Name</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="settings-form-row">
                    <div className="settings-form-column">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="settings-form-column">
                        <label>Age</label>
                        <select
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            required
                        >
                            {Array.from({ length: 8 }, (_, i) => i + 3).map((ageOption) => (
                                <option key={ageOption} value={ageOption}>
                                    {ageOption}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-buttons">
                    <button type="submit">Update</button>
                    <button
                        type="button"
                        className="back-button"
                        onClick={() => navigate('/main')}
                    >
                        Back to Main
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Settings;
