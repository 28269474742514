import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/api';
import './Login.css'; // Assuming you move the CSS to a separate file

const API_URL = 'https://server.readingfun.net';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
console.log('login run');
            const response = await axios.post(`${API_URL}/login`, 
            { username, password },
            { withCredentials: true }  // Ensure cookies are sent with the request
            );
            navigate('/main');
        } catch (error) {
            alert('Invalid credentials');
        }
    };

    return (
        <div className="login-container">
        <h2>Reading For Fun!</h2>
            {/* Add the logo */}
            <img src="/images/ReadingFun-icon.png" alt="Reading Logo" className="reading-logo" />

            <form onSubmit={handleLogin}>
                <label>Username</label>
                <input 
                    type="text" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required 
                />
                <label>Password</label>
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                />
                <button type="submit">Login</button>
            </form>

            {/* Register button */}
            <button 
                className="register-button" 
                onClick={() => navigate('/registration')}
            >
                Register
            </button>
        </div>
    );
};

export default Login;
