import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const API_URL = 'https://server.readingfun.net';

const Register = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('3');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();

        // Check if username already exists
        const isUsernameAvailable = await checkUsernameAvailability(userName);
        if (!isUsernameAvailable) {
            alert("Username is already taken. Please choose another.");
            return;
        }

        try {
            const newUser = {
                username: userName,
                password: password,
                first_name: firstName,
                last_name: lastName,
                email: email,
                age: age
            };

            const response = await axios.post(`${API_URL}/register`, newUser);

            if (response.status === 201) {
                alert('Registration successful!');
                navigate('/login');
            }
        } catch (error) {
            console.error(error);
            alert('Registration failed. Please try again.');
        }
    };

    const checkUsernameAvailability = async (username) => {
        try {
            const response = await axios.post(`${API_URL}/check_username`, { username });
            return !response.data.exists; // Return true if the username does not exist
        } catch (error) {
            console.error("Error checking username availability:", error);
            alert("Error checking username availability. Please try again.");
            return false;
        }
    };

    return (
        <div className="register-container">
            <img src="/images/ReadingFun-icon.png" alt="Reading Logo" className="reading-logo" />
            <form onSubmit={handleRegister} className="register-form">
                <div className="register-form-row">
                    <div className="register-form-column">
                        <label>Username</label>
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="register-form-column">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="register-form-row">
                    <div className="register-form-column">
                        <label>First Name</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="register-form-column">
                        <label>Last Name</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="register-form-row">
                    <div className="register-form-column">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="register-form-column">
                        <label>Age</label>
                        <select
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            required
                        >
                            {Array.from({ length: 8 }, (_, i) => i + 3).map((ageOption) => (
                                <option key={ageOption} value={ageOption}>
                                    {ageOption}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-buttons">
                    <button type="submit">Register</button>
                    <button
                        type="button"
                        className="back-button"
                        onClick={() => navigate('/login')}
                    >
                        Back to Login
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Register;
