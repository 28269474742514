import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Main.css';

const API_URL = 'https://server.readingfun.net';

const Main = () => {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bookLoading, setBookLoading] = useState(false);
    const [passwordPromptVisible, setPasswordPromptVisible] = useState(false);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/books`, { withCredentials: true });
                if (Array.isArray(response.data.books)) {
                    setBooks(response.data.books);
                } else {
                    console.error("Books data is not an array:", response.data.books);
                }
            } catch (error) {
                console.error('Error fetching books:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchBooks();
    }, []);

    const loadBook = async (bookId) => {
        setBookLoading(true);
        try {
            const response = await axios.get(`${API_URL}/book/${bookId}`, { withCredentials: true });
            if (response.status === 200) {
                navigate(`/bookread/${bookId}`);
            } else {
                console.error('Error loading book:', response.status);
            }
        } catch (error) {
            console.error('Error loading book:', error);
        } finally {
            setBookLoading(false);
        }
    };

    const handleSettingsClick = () => {
        // Show password prompt modal
        setPasswordPromptVisible(true);
    };

    const handlePasswordSubmit = async () => {
        try {
            const response = await axios.post(`${API_URL}/check_password`, { password }, { withCredentials: true });
            if (response.data.valid) {
                setPasswordPromptVisible(false);
                navigate('/settings');
            } else {
                alert("Invalid password. Please try again.");
            }
        } catch (error) {
            console.error("Error verifying password:", error);
            alert("Failed to verify password. Please try again.");
        }
    };

    if (loading) {
        return (
            <div id="loading-overlay">
                <p>Loading books, please wait...</p>
            </div>
        );
    }

    if (!Array.isArray(books) || books.length === 0) {
        return <div>No books available</div>;
    }

    return (
        <div className="main-container">
            <h1>Welcome to Reading Fun</h1>
            <img src="/images/ReadingFun-icon.png" alt="Reading Logo" className="reading-logo" />
            <h2>Your Available Books</h2>

            <div className="book-list-container">
                <ul className="book-list">
                    {books.map((book, index) => (
                        <li 
                            key={book.Book_ID} 
                            onClick={() => loadBook(book.Book_ID)}
                            style={{ backgroundColor: ['#66CC66', 'blue', 'orange', 'red'][index % 4] }}
                        >
                            {book.Book_Title}
                        </li>
                    ))}
                </ul>
            </div>

            {bookLoading && (
                <div id="book-loading-overlay">
                    <p>Loading book, please wait...</p>
                </div>
            )}
            <button className="settings-button" onClick={handleSettingsClick}>
                Settings
            </button>
            <button className="logout-button" onClick={() => navigate('/login')}>
                Logout
            </button>

            {/* Password prompt modal */}
            {passwordPromptVisible && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Enter your password to access settings</h3>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder="Password" 
                        />
                        <button onClick={handlePasswordSubmit}>Submit</button>
                        <button onClick={() => setPasswordPromptVisible(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Main;
