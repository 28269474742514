import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBookPages } from '../services/api';
import './Book.css'; 
import axios from 'axios';

const API_URL = 'https://server.readingfun.net';
let newIndex;

const BookRead = () => {
    const { bookId } = useParams();
    const [bookTitle, setBookTitle] = useState("");
    const [pages, setPages] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [audio, setAudio] = useState(null);
    const [audioReady, setAudioReady] = useState(false);
    const [wordTimings, setWordTimings] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const [highlightTimeouts, setHighlightTimeouts] = useState([]);
    const readingBoxRef = useRef(null);
    const [popupContent, setPopupContent] = useState("");
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const popupContentRef = useRef(null); 
    const [isHighlight, setIsHighlight] = useState(false);
    const cbReadRef = useRef(null); // Use ref for the checkbox

    useEffect(() => {
        const getPages = async () => {
            const result = await fetchBookPages(bookId);
            setBookTitle(result.data.book_title);  
            setPages(result.data.pages);           
            setLoading(false);
        };
        getPages();
    }, [bookId]);

    const isPausedRef = useRef(isPaused);

    useEffect(() => {
        isPausedRef.current = isPaused;
    }, [isPaused]);

    useEffect(() => {
        const currentPage = pages[currentPageIndex];
        if (currentPage && currentPage.audiofile) {
            const newAudio = new Audio(`data:audio/mp3;base64,${currentPage.audiofile}`);
            setAudio(newAudio);
            setWordTimings(JSON.parse(currentPage.wordtiming));
            setAudioReady(false);
            
            newAudio.addEventListener('canplaythrough', () => {
                setAudioReady(true);
            });
            newAudio.load();
        } else {
            setAudio(null);
        }
    }, [currentPageIndex, pages]);

    const handleNextPage = () => {
        if (currentPageIndex < pages.length - 1) {
            setCurrentPageIndex(currentPageIndex + 1);
            clearHighlights();
        }
    };

    const handlePrevPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
            clearHighlights();
        }
    };

// Start reading from the first word
const startReading = () => {
    setCurrentWordIndex(0);
    setIsPaused(false);
    setIsStopped(false);
    clearTimeouts();
    clearHighlights();
    window.speechSynthesis.cancel(); // Stop any ongoing speech

    if (cbReadRef.current.checked) {
        highlightWords(0); // Start from the first word
    } else if (audio && audioReady) {
        audio.currentTime = 0;
        audio.play();
    }
};

const pauseAudio = () => {
    setIsPaused(true);
    window.speechSynthesis.pause(); // Pause speech synthesis
    if (audio && audioReady) {
        audio.pause();
    }
};

const resumeAudio = () => {
    setIsPaused(false);
if (cbReadRef.current.checked) {
    window.speechSynthesis.resume(); // Resume speech synthesis
   }
else if (audio && isPaused && audioReady) {
        audio.play();
    }
};

const stopAudio = () => {
    setIsStopped(true);
    setIsPaused(false);
    setCurrentWordIndex(0);
    clearTimeouts();
    clearHighlights();
    window.speechSynthesis.cancel(); // Stop any ongoing speech
    if (audio && audioReady) {
        audio.pause();
        audio.currentTime = 0;
    }
};

const wrapWordsInSpans = (text) => {
    return text.split(' ')
        .filter(word => word.trim() !== '') // Filter out empty strings or whitespace
        .map((word, index) => (
            <React.Fragment key={index}>
                <span className="word" onClick={() => handleWordClick(word)}>
                    {word}
                </span>{' '}
            </React.Fragment>
        ));
};

const highlightWords = (index) => {
    clearTimeouts();
    const words = document.querySelectorAll('.word');

    if (isPaused || isStopped) return; // Stop highlighting if paused or stopped

    // Ensure index is within the bounds of the words array
    if (index < words.length) {
        const word = words[index];
        const wordText = word ? word.textContent : '';

        // Clear previous highlights
        words.forEach(w => w.classList.remove('highlight'));

        // Highlight the current word if it exists
        if (word) {
            word.classList.add('highlight');
            scrollToWord();
        }

        // Speak the current word and advance to the next index after it�s spoken
        if (wordText) {
            speakWord(wordText, index + 1, cbReadRef.current.checked); // Pass the next index and highlighting state
        }
    } else {
        console.warn("Index is out of bounds:", index);
    }
};

let voices = [];

// Load voices when they are available
const loadVoices = () => {
    voices = window.speechSynthesis.getVoices();
    if (voices.length === 0) {
        window.speechSynthesis.onvoiceschanged = () => {
            voices = window.speechSynthesis.getVoices();
        };
    }
};

// Call loadVoices initially
loadVoices();

const speakWord = (word, nextIndex, isHighlighting) => {
    if (isStopped || isPaused) return; // Do not proceed if stopped or paused

    const utterance = new SpeechSynthesisUtterance(word);

    // Choose a female voice if available
    const femaleVoice = voices.find(voice => /female/i.test(voice.name) || /female/i.test(voice.voiceURI));
    if (femaleVoice) {
        utterance.voice = femaleVoice;
    }

    window.speechSynthesis.speak(utterance);

    utterance.onend = () => {
        if (!isPaused && !isStopped) { 
            const delay = isHighlighting ? 100 : 500; // Adjust delay as desired
            setTimeout(() => {
                setCurrentWordIndex(nextIndex);
                highlightWords(nextIndex);
            }, delay);
        }
    };
};
 	
const scrollToWord = () => {
    const highlightedWord = document.querySelector('.highlight');
    if (highlightedWord) {
        readingBoxRef.current.scrollTop = highlightedWord.offsetTop - readingBoxRef.current.offsetTop;
    }
};

const clearHighlights = () => {
    document.querySelectorAll('.word').forEach(word => word.classList.remove('highlight'));
};

const clearTimeouts = () => {
    highlightTimeouts.forEach(timeout => clearTimeout(timeout));
    setHighlightTimeouts([]);
};

if (loading) {
    return <div><h1>Loading Book, please wait...</h1></div>;
}

    const lookupWord = async (word, sentence, pageid) => {
        try {
            const response = await axios.get(`${API_URL}/lookup`, { params: { word, sentence, pageid } });
            return response.data;  // Return the response data
        } catch (error) {
            console.error('API call failed:', error);
            throw error;  // Rethrow the error to be handled by the caller
        }
    };

    const handleWordClick = async (word) => {
        const sentence = extractSentence(word, pages[currentPageIndex].PageText);
        const pageid = currentPage.Page_ID;
        setPopupContent("Retrieving meaning...");
        setIsPopupVisible(true);

        if (popupContentRef.current) {
            popupContentRef.current.classList.add('flashing');
        }

        try {
            const data = await lookupWord(word, sentence, pageid);
            setPopupContent(data.Meaning);

            if (popupContentRef.current) {
                popupContentRef.current.classList.remove('flashing');
            }

        } catch (error) {
            console.error('Error fetching word meaning:', error);
            if (popupContentRef.current) {
                popupContentRef.current.classList.remove('flashing');
            }
            setPopupContent("Error retrieving meaning");
        }
    };

    const extractSentence = (word, text) => {
        const sentences = text.match(/[^.!?]+[.!?]+/g);
        for (let sentence of sentences) {
            if (sentence.includes(word)) {
                return sentence.trim();
            }
        }
        return text;
    };

    const showPopup = (meaning) => {
        setPopupContent(meaning);
        setIsPopupVisible(true);
    };


const currentPage = pages[currentPageIndex];

return (
    <div className="book-container">
        <h2>{bookTitle}</h2> 
        <div id="page-title">
            <h3>Page {currentPage.PageNum}:</h3>
            <h3>{currentPage.PageTitle}</h3>
        </div>
        <div id="control-buttons">
            <input type="checkbox" id="cbRead" ref={cbReadRef} defaultChecked={true} />
            <label htmlFor="cbRead">Highlighting</label> 
            
            <button id="read-button" onClick={startReading}>Read</button>
            <button id="pause-button" onClick={pauseAudio}>Pause</button>
            <button id="resume-button" onClick={resumeAudio}>Resume</button>
            <button id="stop-button" onClick={stopAudio}>Stop</button>
        </div>

        <div id="navigation-buttons">
            <button id="previous-button" onClick={handlePrevPage} disabled={currentPageIndex === 0}>Previous</button>
            <button id="next-button" onClick={handleNextPage} disabled={currentPageIndex === pages.length - 1}>Next</button>
            <button id="back-to-main-button" onClick={() => window.location.href = '/main'}>Back to Books</button>
        </div>

        <div id="container">
            {currentPage.pgPicture && (
                <img id="page-image" src={`/${currentPage.pgPicture}`} alt="Page" />
            )}
            <div id="reading-box" ref={readingBoxRef}>
                <p id="page-reading-text">
                    {wrapWordsInSpans(currentPage.PageText)}
                </p>
            </div>
        </div>

        {/* Popup for word meaning */}
        {isPopupVisible && (
            <div id="popup" style={{ display: isPopupVisible ? 'block' : 'none' }}>
                <h4>Word Meaning</h4>
                <p ref={popupContentRef} id="popup-content">{popupContent}</p>
                <button id="popup-close" onClick={() => setIsPopupVisible(false)}>Close</button>
            </div>
        )}
    </div>
);
};

export default BookRead;
