import axios from 'axios';

const API_URL = 'https://server.readingfun.net';

export const loginUser = (username, password) => {
    console.log('Sending login request with:', username, password);
    
    return axios.post(`${API_URL}/login`, 
        { username, password },  // Send data as JSON object
        { headers: { 'Content-Type': 'application/json' } }  // Specify JSON content type
    )
    .then(response => {
        console.log('Login successful:', response.data);
        return response.data;
    })
    .catch(error => {
        console.error('Error during login:', error.response ? error.response.data : error.message);
        throw error;
    });
};

export const fetchBooks = () => {
    return axios.get(`${API_URL}/main`);
};

export const fetchBookPages = (bookId) => {
    return axios.get(`${API_URL}/book/${bookId}`, {}, { withCredentials: true });
};

// lookupWord function
export const lookupWord = async (word, sentence) => {
    try {
        const response = await axios.get(`${API_URL}/lookup`, { params: { word, sentence } });
        return response.data;  // Return the response data
    } catch (error) {
        console.error('API call failed:', error);
        throw error;  // Rethrow the error to be handled by the caller
    }
};

